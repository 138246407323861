import { Component, OnInit, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { CartService } from '../cart.service';
import { UtilsService } from '../utils.service';
import { OrderInfo, Address, ContactInfo, DeliveryTime, CountryListDto, governate, Area, PaymentMethodsLookups, CartItem, TruckInfo, AppEventType, DeliveryTimeWrapper } from '../models';
import { ApiService } from '../api.service';
import { Cart } from '../cart';
import { AreaComponent } from '../area/area.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule, NgForm, ControlContainer } from '@angular/forms';
import Swal from 'sweetalert2'
import { MapsAPILoader, MouseEvent, AgmMap } from '@agm/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash'
import { SubstitutionComponent } from '../substitution/substitution.component';
import { OrderSubmittedComponent } from '../order-submitted/order-submitted.component';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { slideInUpOnEnterAnimation } from 'angular-animations';
declare var google: any;

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.css'],
  animations: [slideInUpOnEnterAnimation()]
})
export class CheckOutComponent implements OnInit, AfterViewInit {

  allowEmail = environment.enable_email_at_checkout;
  allowMembershipNumber = environment.allow_membership_number;
  preferredCountries: CountryISO[] = [CountryISO.Kuwait, CountryISO.Qatar];
  SearchCountryField = SearchCountryField;
  selectedCountryCode: string;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  phoneNumber: any;
  isOrderLoading: boolean = false;
  @ViewChild('frmPayment') frmPayment: NgForm;
  @ViewChild('frmContact') frmContact: NgForm;
  @ViewChild('frmTerms') frmTerms: NgForm;


  lat: number = 29.37612527749745;
  lng: number = 47.99124357866814;
  env = environment;
  showArea = environment.show_area;
  areaRequired = environment.area_required;
  countries: CountryListDto[] = [];
  governates: governate[] = [];
  areas: Area[] = [];
  paymentMethods: PaymentMethodsLookups[] = [];
  zoom: number = 12;
  markerLat: number;
  markerLng: number;
  address: string;
  minDeliveryTime: string;
  minEstDeliveryTime: string;
  isOpen: boolean;
  isShipping: boolean;
  private geoCoder;
  cartAddress : {};
  allowSubstitution: boolean = environment.ask_for_substitution;
  allowPromoCodes = environment.allow_promo_codes;
  allowCash = !environment.disable_cash;
  invalidLocation: boolean = false;
  isLoading: boolean = false
  isCalculatingShipping = false;
  acceptTerms = true;
  promoCode: string = '';
  isValidatingPromoCode: boolean = false;
  cart: Cart
  deliveryTimes: DeliveryTime[]
  pickupAddress: Address = {
    countryId: -1,
    areaId: -1,
    governateId: -1,
    contactPhone: '',
  }
  paymentMethod: string = "";
  cardPayments = [];

  paymentForm: FormGroup;
  contactForm: FormGroup;
  termsForm: FormGroup;

  submitted = false;
  contactPhoneLengthError = false;
  cb; w;
  paymentInProgress = false;
  expectedDeliveryTime: Date;
  operatingHours: any = {};
  allowShipping = environment.allow_shipping;
  defaultCountry = environment.default_country;
  allowTerms = environment.allow_terms;
  changeAddressUrl:[string] = ['/pre-checkout']
  truck: TruckInfo
  @ViewChild(SubstitutionComponent) substitutionSelector: SubstitutionComponent;
  @ViewChild(AreaComponent) areaSelector: AreaComponent;
  @ViewChild('agMap') agMap: AgmMap;
  constructor(
    private srvCart: CartService,
    public utils: UtilsService, private api: ApiService,
    private toaster: ToastrService, private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
      this.initForms();
      this.cart = srvCart.getCart();
      this.pickupAddress = this.cart.orderInfo.pickupAddress || {}
      this.selectedCountryCode = this.pickupAddress.countryCode || (this.cart.$truck && this.cart.$truck.defaultCountry && this.cart.$truck.defaultCountry.code2) || 'kw';

      this.phoneNumber = this.pickupAddress.contactPhone;
      setTimeout(() => console.log(this.phoneNumber, this.pickupAddress.contactPhone), 1500)
      this.paymentMethod = this.cart.orderInfo.paymentMethod || 'cashOnDelivery';
      this.promoCode = this.cart.orderInfo.promoCode;
      if (this.cart.$truck)
      this.paymentMethods = this.cart.$truck.paymentMethodsLookups;
      if(this.cart.serviceType_ex == 'catering'){
        this.paymentMethods = _.filter(this.paymentMethods, d=>d.paymentMethod != 'cashOnDelivery');
      }
      if (this.promoCode && this.allowPromoCodes) {
        this.srvCart.setPromoCode('', 0);
        //recalculate the promo code every time user enters this screen
        this.redeemOrDeletePromoCode();
      }
      let that = this;
      // api.getCountries().subscribe(countries => {
        //   this.countries = countries;
        //   if (this.allowShipping && !this.pickupAddress.countryId)
        //     this.pickupAddress.countryId = this.defaultCountry;
        //   this.storeAddress();
        // });
        this.isLoading = true;
        api.viewTruck().subscribe(t => {
          let defaultCountry = (this.cart.$truck && this.cart.$truck.defaultCountry && this.cart.$truck.defaultCountry.id) || environment.default_country
          this.isShipping = this.pickupAddress.countryId && this.pickupAddress.countryId != -1 && this.pickupAddress.countryId != defaultCountry;
      if (!this.isShipping) {
        api.getDeliveryTimes(new Date(), this.cart.orderInfo.serviceType, this.api._orderFromCart(this.cart).cartItems).subscribe(times => {
          let laterOptions = (times.laterOptions || ([] as DeliveryTimeWrapper[])).filter(d => d.deliveryTime.type == 'Custom' || d.deliveryTime.type == 'AsSoonAs');
          this.deliveryTimes = laterOptions.map(d => d.deliveryTime);
        });
      }
      if (!this.isShipping)
      this.srvCart.setShippingCharge(0);
    else {
      if (this.paymentMethod == 'cashOnDelivery')
        this.paymentMethod = this.cardPayments[0] && this.cardPayments[0].value;
      this.storePaymentMethod();
      this.isCalculatingShipping = true;
      this.api.calculateShipping(this.cart).subscribe(shipping => {
        this.srvCart.setShippingCharge(shipping.shippingCharge);
        this.isCalculatingShipping = false;
      })
    }
      this.isLoading = false;
      //this.srvCart.setTruck('delivery', t.truck);
      this.truck = t.truck;
      this.allowShipping = t.truck.allowShippingOutsideCountry;
      this.allowSubstitution = t.truck.enableSubstitutionOptions;
      this.paymentMethods = t.truck.paymentMethodsLookups;
      if (t.truck.minDeliveryTime) {
        let parts = _.split(t.truck.minDeliveryTime, ':');
        that.minDeliveryTime = ((Number(parts[0]) * 60) + Number(parts[1])).toString();
        //that.minDeliveryTime = parts[1];
      }
      that.isOpen = this.isShipping || utils.isNowBetween(t.truck.operatingFrom, t.truck.operatingTo);
      that.operatingHours = {
        from: that.utils.parseTimeSpan(that.truck.operatingFrom),
        to: that.utils.parseTimeSpan(that.truck.operatingTo)
      }
    });


    if (environment.card_payment) {
      _.each(_.split(environment.card_payment, ','), pm => {
        this.cardPayments.push({
          name: 'card_' + pm,
          value: pm
        });
      })
    } else {
      this.cardPayments.push({
        name: 'Online Payment',
        value: 'myfatoorah'
      });
    }
  }

  // convenience getter for easy access to form fields
  get fc() { return this.contactForm.controls; }
  get fp() { return this.paymentForm.controls; }
  get ft() { return this.termsForm.controls; }
  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   //console.log('Resizing');
    //   this.agMap.triggerResize();
    // }, 100);
    if (environment.ask_for_substitution && !this.cart.orderInfo.substitution)
      setTimeout(() => this.substitutionSelector.selectSubstitution(() => { }));
  }
  ngOnInit() {
    this.checkOrderCycle();
    if(this.api.isLoggedIn && !this.cart.orderInfo.pickupAddress.selectedAddressId){
      this.changeAddressUrl = ['/select-address'];
      return;
    }
    this.utils.fbTrack('AddPaymentInfo');
    this.api.trackEvent(AppEventType.Checkout);
    //$('.page-side').removeClass('sm-hidden');



    //this.setMap();
  }
  private initForms(){
    this.paymentForm = this.formBuilder.group({
      paymentMethod: ['', Validators.required],

    });
    this.contactForm = this.formBuilder.group({
      contactName: ['', Validators.required],
      contactPhone: ['', Validators.required],
      contactEmail: ['', Validators.required],
      membershipNo: [''],

    });
    if (!environment.enable_email_at_checkout) {
      this.contactForm.get('contactEmail').disable();
    }
    this.termsForm = this.formBuilder.group({
      acceptTerms: ['', (control) => {
        return control.value === true ? null : { required: { value: control.value } };
      }],
    });
  }
  getOrderDeliveryTimeEst() {

    this.api.calculateDeliveryTime({ areaId: this.pickupAddress.areaId }).subscribe(it => {
      this.minEstDeliveryTime = it.toString();
      console.log("est time" + this.minEstDeliveryTime);
    });
  }
  ngOnDestroy() {
    //$('.page-side').addClass('sm-hidden')

    if (this.cb)
      window.removeEventListener('message', this.cb);
  }
  storePaymentMethod() {
    this.srvCart.setPaymentMethod(this.paymentMethod)
  }
  redeemOrDeletePromoCode() {
    if (this.cart.orderInfo.promoCode) {
      this.srvCart.setPromoCode('', 0);
      this.promoCode = '';
      return;
    }

    this.isValidatingPromoCode = true;
    let that = this;
    var orderModel = this.api._orderFromCart(this.cart);
    var cartItems = orderModel.cartItems;
    this.api.redeemPromoCode({
      promoCode: this.promoCode,
      paymentMethod: this.paymentMethod,
      itemsTotal: this.cart.$subTotal,
      truckId: this.cart.$truck.id,
      cartItems: cartItems,

    }).subscribe(function (res) {
      that.srvCart.setPromoCode(that.promoCode, res.discountValue);
      that.isValidatingPromoCode = false;
    }, function (err) {
      that.isValidatingPromoCode = false;
      that.error(err);
    })
  }
  storeAddress() {
    let shipping = false;
    if (this.allowShipping) {
      let country = _.find(this.countries, c => c.id == this.pickupAddress.countryId)
      if (country) {
        if (country.code2 != 'kw') {
          shipping = true;
          this.showArea = false;
          this.areaRequired = false;

        }
        else {
          shipping = false;
          this.showArea = environment.show_area;
          this.areaRequired = environment.area_required;
        }
        this.api.getGovernate(country.id).subscribe(gov => {
          this.governates = gov.items;
          let governate = _.find(this.governates, g => g.id == this.pickupAddress.governateId)
          if (governate)
            this.areas = governate.areas;

        });

      }
    }
    if (this.phoneNumber) {
      this.pickupAddress.contactPhone = this.phoneNumber.internationalNumber;
      this.pickupAddress.countryCode = this.phoneNumber.countryCode;
    }

    this.srvCart.setAddress(this.pickupAddress);
    if (!shipping)
      this.srvCart.setShippingCharge(0);
    else {
      if (this.paymentMethod == 'cashOnDelivery')
        this.paymentMethod = this.cardPayments[0] && this.cardPayments[0].value;
      this.storePaymentMethod();
      this.isCalculatingShipping = true;
      this.api.calculateShipping(this.cart).subscribe(shipping => {
        this.srvCart.setShippingCharge(shipping.shippingCharge);
        this.isCalculatingShipping = false;
      })
    }
  }



  error(msg, title = 'alert') {
    let code;
    if (msg) {
      if (msg.error) {
        if (msg.error.data)
          msg = msg.error.data;
        else
          msg = msg.error;
        code = msg.errorCode;
      }
      if (msg.errorDetail) {
        msg = msg.errorDetail;
        code = msg.code;
      }
      if (msg.errorMessage) {
        msg = msg.errorMessage;
        code = msg.code;
      }
      if (msg.message) {
        msg = msg.message;
        code = msg.code;
      }
    }
    if (code) {
      code = "err_" + code;
      let tmsg = this.translate.instant(code);
      if (tmsg !== code) {
        msg = code
      }
    }
    return this.notify('warning', title, msg)
  }
  warn(msg, title = 'warning') {
    return this.notify('warning', title, msg)

  }
  success(msg, title = 'warning') {
    return this.notify('success', title, msg)

  }
  notify(type, title, msg) {
    if (!title)
      title = type;
    title = this.translate.instant(title)
    if (!msg)
      msg = type
    msg = this.translate.instant(msg)

    const confirmButtonText = this.translate.instant('OK');
    return Swal.fire(
      title,
      msg,
      type,
    )
  }

  payOnline(data, x) {
    return new Observable(observable => {
      this.paymentInProgress = true;

      // this.cb = function (event) {
      //   if (event.data === 'payment_confirmed')
      //     observable.next();
      //   else {
      //     observable.error('Your payment is rejected, please try again');
      //   }
      //   window.removeEventListener('message', this.cb);
      //   this.cb = undefined;
      //   this.closePaymentWindow();
      // }
      //window.addEventListener("message", this.cb, false);

      if (data.paymentUrl)
        x.location = data.paymentUrl;
    });
  }
  validatePhoneNumber() {

    if (!this.pickupAddress.contactPhone.match(/\+?\d/g) ||
      this.pickupAddress.contactPhone.length < 12 || this.pickupAddress.contactPhone.length > 18) {
      this.contactPhoneLengthError = true;
      return;
    }
    this.contactPhoneLengthError = false;

  }
  closePaymentWindow() {
    try {
      this.w.close();
    } catch (err) {
      //console.log(err);
    }
  }

  submitOrder(confirmed: boolean = false) {
    // if(!this.pickupAddress.location.lng && !this.pickupAddress.location.lat)
    // {
    //   this.invalidLocation = true;
    //   return;
    // }
    if(this.isOrderLoading) //already loading order
      return;
    this.submitted = true;
    this.isOrderLoading = true;
    console.log(this.isOrderLoading);
    if (this.paymentMethods.length) {
      this.frmPayment.ngSubmit.emit();
      if (!this.paymentForm.valid) {
        this.isOrderLoading = false;
        return;
      }
    }
    if (this.allowTerms) {
      this.frmTerms.ngSubmit.emit();
      if (!this.termsForm.valid) {
        this.isOrderLoading = false;
        return;
      }
    }
    this.frmContact.ngSubmit.emit();
    if (!this.contactForm.valid){
      this.isOrderLoading = false;
      return;
    }


    let defaultCountry = (this.cart.$truck && this.cart.$truck.defaultCountry && this.cart.$truck.defaultCountry.id) || environment.default_country
    let isShipping = this.pickupAddress.countryId && this.pickupAddress.countryId != -1 && this.pickupAddress.countryId != defaultCountry;
    if (!this.isOpen && !isShipping && !this.cart.orderInfo.pickupTime) {
      this.warn(
        this.translate.instant('operating_hours_warning', this.operatingHours),
        'closed_warning');
        this.isOrderLoading = false;
      return;
    }
    if (!this.srvCart.validateCart()){
      this.isOrderLoading = false;
      return;
    }

    if (this.allowTerms && !this.acceptTerms) {
      this.warn(
        this.translate.instant('MUST_ACCEPT_TERMS', this.operatingHours),
        'Sorry');
        this.isOrderLoading = false;
      return;
    }
    this.storePaymentMethod();

    this.submitted = true;

    this.contactPhoneLengthError = false;





    if (!isShipping && this.areaRequired && !this.pickupAddress.areaId) {
      this.isOrderLoading = false;
      this.areaSelector.selectArea(() => {

        this.submitOrder(); //call submit order again if area is selected
      });
      return;
    }

    // Required Substitution
    if (this.allowSubstitution && !this.cart.orderInfo.substitution) {
      this.isOrderLoading = false;
      this.substitutionSelector.selectSubstitution(() => {
        this.submitOrder();
      });
      return;
    }


    if (this.isLoading){
      this.isOrderLoading = false;
      return; //already loading something
    }
    if (!confirmed && this.utils.askForMembershipModal.askForMembership) {
      this.isOrderLoading = false;
      this.utils.askForMembershipModal.showModal(() => {
        //Do Nothing?
        this.submitOrder(true);
      });
      return;
    }
    this.cart.orderInfo.pickupAddress.areaId = this.pickupAddress.areaId;
    if (this.paymentMethod != 'cashOnDelivery') {
      var w = 800;
      var h = 600;
      var left = (screen.width / 2) - (w / 2);
      var top = (screen.height / 2) - (h / 2);
      this.w = window.open(
        "", "_self");

      window.focus();
    }
    this.isLoading = true;
    this.utils.fbTrack('InitiateCheckout')
    let serviceType = this.cart.orderInfo.serviceType;
    this.api.submitOrder(this.cart).subscribe(res => {
      //this.isOrderLoading = false;
      if (serviceType == 'catering' || this.paymentMethod == 'cashOnDelivery')
        this.srvCart.emptyCart();
      this.utils.fbTrack('Purchase', {
        value: this.cart.$total,
        currency: environment.currency_code
      });
      this.api.trackEvent(AppEventType.PlaceOrder, res.orderId);
      let p = new Observable(o => { o.next(); });
      if(serviceType == 'catering' && this.api.isAuthenticated){
        this.router.navigate(['/my-orders']);
        return;
      }
      if (this.paymentMethod != 'cashOnDelivery' && res.paymentUrl) {
        p = this.payOnline(res, this.w);
      }
      p.subscribe(o => {
        //this.srvCart.emptyCart();
        //this.success(this.translate.instant('Order submitted'), this.translate.instant('Thank you'));
        this.isLoading = false;
        this.router.navigate(['/order/' + res.orderId, { ot: res.type }], {skipLocationChange:true, replaceUrl:true });
      }, err => {
        this.closePaymentWindow();
        this.isLoading = false;



        this.phoneNumber = this.pickupAddress.contactPhone;
        this.warn(this.translate.instant("WE_ARE_BUSY"), 'sorry');
      });
    }, res => {
      this.isOrderLoading = false;
      this.closePaymentWindow();
      //console.log(err);
      let err = res.error
      this.isLoading = false;
      if (err && err.code == '5500') {
        let msg = _.join(_.map(err.errorDetail.errors, e =>
          this.translate.instant('c_err_' + e.code, { err: e })), '<br/>');
        this.router.navigate(['/cart'])
        this.warn(msg, 'CART_CONFLICT').then(function () {
          location.reload();
        });

        return;
      } else {
        let msg = this.translate.instant('err_' + err.code, {
          type: this.translate.instant('srv_' + this.cart.orderInfo.serviceType)
        });
        if (msg && !_.includes(msg, 'err')) {
          this.warn(msg, 'CART_CONFLICT').then(function () {

            this.router.navigate(['/cart']);
          });
          return;
        }
      }
      this.phoneNumber = this.pickupAddress.contactPhone;
      this.warn(this.translate.instant("WE_ARE_BUSY"), 'sorry');
    });
  }

  // Set Map and Marker Location
  setMap() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      if (!this.pickupAddress.location.lng && !this.pickupAddress.location.lat) {
        this.setCurrentLocation();
      }
      else {
        this.markerLat = this.pickupAddress.location.lat;
        this.markerLng = this.pickupAddress.location.lng;
        this.lat = this.pickupAddress.location.lat;
        this.lng = this.pickupAddress.location.lng;
        this.getAddress(this.lat, this.lng);
      }
    });
  }

  //Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.markerLat = position.coords.latitude;
        this.markerLng = position.coords.longitude;
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 12;
        this.getAddress(this.markerLat, this.markerLng);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    //console.log($event);
    this.markerLat = $event.coords.lat;
    this.markerLng = $event.coords.lng;
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.markerLat, this.markerLng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.zoom = 12;
        this.pickupAddress.addressLine1 = results[0].formatted_address;
        this.pickupAddress.location.lng = longitude;
        this.pickupAddress.location.lat = latitude;
        this.storeAddress();
        this.getOrderDeliveryTimeEst();
      }
    });
  }

  checkOrderCycle() {
    if (this.cart.cartItems.length == 0) {
      this.router.navigate(['/']);
      return;
    }
    let isShipping = this.pickupAddress.countryId && this.pickupAddress.countryId != -1 && this.pickupAddress.countryId != environment.default_country;
    if (
      this.cart.orderInfo.serviceType == 'delivery' && (
        (!isShipping && this.areaRequired && !this.pickupAddress.areaId)
        || !this.pickupAddress.blockNo || !this.pickupAddress.street || !this.pickupAddress.buildingNo
        // || !isShipping && (!(this.cart.orderInfo.pickupTime || this.srvCart.expectedDeliveryTime))
        )) {

      this.router.navigate(['/confirm-address']);
      return;
    }
    if(!this.cart.serviceType_ex){
      this.router.navigate(['/service-area', {continue:['/checkout']}], {skipLocationChange:true, replaceUrl:true});
      return;
    }
    if ((!isShipping && !(this.cart.orderInfo.pickupTime || this.srvCart.expectedDeliveryTime))) {
      this.router.navigate(['/time-date', {continue:['/checkout']}], {skipLocationChange:true, replaceUrl:true});
      return;
    }
  }


  goBack() {
    this.utils.goBack();
    //this.router.navigateByUrl('/checkout-info');
  }
}
